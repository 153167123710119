@import '../../styles/variables';

.connect-social {
	list-style: none;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    width: 400px;
    margin: 0 auto;
	li {
		&:not(:last-of-type) {
			margin-right: 4vmin;
		}
		a {
			color: #ffffff;
			path {
				transition: $transition-speed;
			}
			&:hover {
				.npm-border {
					fill: var(--main-app-text-color);
				}
				.npm-body {
					fill: var(--secondary-color);
				}
				.linkdin, .twitter {
					fill: var(--primary-color);
				}
				.github {
					fill: var(--main-app-text-color);
				}
			}

		}
	}
	&__npm {
		width: 100px;
		a {
			vertical-align: middle;
		}
	}
}
