@import 'styles/variables';

@import './styles/reset';

.app {
	display: grid;
	grid-template-rows: auto 1fr auto;
	background-color: var(--main-app-bg);
	overflow: hidden;
	color: var(--main-app-text-color);
	&--page {
		max-width: 968px;
		display: grid;
		margin: 2vmin auto 0 auto;
		padding: 0 $margin * 2;
	}
}
