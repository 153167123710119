@import "styles/variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  li {
    list-style: none;
  }
}

.fade-enter {
  opacity: 0;
  transform: scale(1.1);
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms, transform 200ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}
