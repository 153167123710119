@import '../../styles/variables';

.app__header {
	text-align: center;
	margin-top: 0;
	&__title {
		font-size: $page-title;
		font-weight: normal;
		margin: 0;
		&--last-name {
			font-weight: lighter;
		}
	}
	&__change-theme {
		position: absolute;
		right: 0;
		top: 0;
		font-size: calc(6px + 0.6vmin);
		font-weight: bold;
		&--light {
			display: inline-block;
			padding: 6px 10px;
			cursor: pointer;
			background-color: $white;
			color: $black;
			&:hover {
				text-decoration: underline;
			}
			&.selected {
				cursor: default;
				&:hover {
					text-decoration: none;
				}
			}
		}
		&--dark {
			display: inline-block;
			padding: 6px 10px;
			cursor: pointer;
			background-color: $black;
			color: $white;
			&:hover {
				text-decoration: underline;
			}
			&.selected {
				cursor: default;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
