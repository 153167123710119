@import '../../styles/variables';

.about {
	max-width: 960px;
	font-size: 18px;
	line-height: 1.8;
	width: 80%;
	margin: 0 auto;
    text-align: center;
	&__me {
		margin: 0  auto calc(24px + 4vmin) auto;
		display: flex;
		border-radius: 50%;
		height: 20vmin;
		width: 20vmin;
		border-color: var(--primary-color);
		border-width: 10px;
		border-style: ridge;
		background-color: var(--primary-color);
	}
	a {
		color: var(--primary-color);
		&:hover {
			color: var(--secondary-color);
		}
	}
	&__name {
		font-size: $hearo-2;
	}
	&__job {
		font-size: $hearo;
	}
	&__tags {
		font-size: $small;
	}
}
